// const path = require('path');

var exports = module.exports = {};

exports.my_name = function() {
    return "ROCK22";
}

// module.exports = {
//   entry: './tcn_flat/static/src/index.js',
//   output: {
//     filename: 'main.js',
//     path: path.resolve(__dirname, './tcn_flat/static/dist')
//   },
//   watchOptions: {
//     ignored: /node_modules/
//   },
// };

// module.exports = {
//     name: name
// }